var Fancybox_opt = {
    autoFocus: true, // Set focus on first focusable element after displaying content. Default: true
    trapFocus: true, // Trap focus inside Fancybox. Default: true
    dragToClose: false,
    mainClass: "simple-modal",
    showClass: "fancybox-fadeIn",
    hideClass: "fancybox-fadeOut",
    template: {
        // Close button icon
        closeButton:
                    '<svg>' +
                        '<use  xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use>' +
                    '</svg>',
        // Loading indicator icon
        main: null,
      },
  };
  Fancybox.bind("[data-src='#simple-modal']", Fancybox_opt);
  // or
  $("body").on("click", "[data-modal]", function () {
    var srcLink = $(this).attr('href')
    Fancybox.show([{ src: srcLink, type: "inline" }], Fancybox_opt);
  });